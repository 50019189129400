@use "../variables" as *;

$card-rounded: 15px;

//! Combination Cards Style
.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  align-items: start;

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: "sm")) {
    gap: 32px;
  }

  .aspect-card {
    padding: 0;
    aspect-ratio: 2 / 1;
    border-radius: $card-rounded;
    cursor: pointer;

    .image-no-scale {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border: 1px solid transparent;
    }

  }

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: "sm")) {
    grid-template-columns: auto;
  }
}

//Card Swiper at Homepage Styles
.card-swiper {
  min-height: 10em;
  .swiper {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

