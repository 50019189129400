.container_option {
    display: flex;
    align-items: center;
  
    &.selected {
      padding-left: 40px;
  
      img {
        position: absolute;
        left: 0;
      }
    }
  
    img {
      width: 28px;
      height: 28px;
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      border: 1px solid lightgray;
      margin-right: 8px;
    }
  }
  