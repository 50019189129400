@use '../variables';

/* custom mat button */
.mdc-button {
  padding: 8px 25px !important;
  border-radius: variables.$rounded-3xl !important;
  height: 45px !important;

  &.mat-accent {
    .mdc-button {
      &__label {
        color: #fff;
      }
    }
  }

  &.button-outline {
    &.mat-primary {
      background-color: transparent !important;
      border: 1px solid variables.$color-primary;
      color: variables.$color-primary !important;
    }
  }
}

.mat-raised-button {
  color: variables.$color-primary;
}

.mat-icon[svgicon='arrow'] svg {
  padding: 1px;
}

.mat-tooltip {
  font-size: 12.75px;
}
