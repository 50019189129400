@use '../variables' as var;

.skeleton-loader {
    .thumbnail-image {
        background-image: url('../../assets/imgs/picture.svg');
        margin: auto;
    }

    .skeleton {
        height: 16px;
        background: linear-gradient(-90deg, var.$iron-color 0%, #d1d1d1 30%, var.$iron-color 50%, var.$iron-color 100%);
        background-size: 400% 400%;
        border-radius: 4px;
        animation: pulse 0.9s linear infinite forwards;

        @keyframes pulse {
            0% {
                background-position: 0% 0%;
            }

            100% {
                background-position: -135% 0%;
            }
        }
    }
}
