@use '../variables' as *;

.mat-mdc-checkbox {
  &.mat-primary {
    &.mat-mdc-checkbox-checked {
      .mdc-form-field {
        .mdc-checkbox {
          &__background {
            border-color: $shamrock-color !important;
            background-color: $shamrock-color !important;
          }

          &__ripple {
            background-color: $shamrock-color !important;
          }
        }
      }
    }
  }
}
