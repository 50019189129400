@forward 'base/color';

/* rounded (like: border-radius) */
$rounded-sm: 8px;
$rounded-md: 10px;
$rounded-lg: 15px;
$rounded-xl: 20px;
$rounded-2xl: 30px;
$rounded-3xl: 50px;
$rounded-full: 50%;

/* spacing (like: margin, padding) */
$spacing-xs: 6px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 30px;

/* scss-docs-start grid-breakpoints */
$grid-breakpoints: (
  /* Mobile */
  'xs': 0,
  /* Tablet */
  'sm': 600px,
  /* Small Laptop */
  'md': 960px,
  /* Large Laptop */
  'lg': 1280px,
  /* Desktop */
  'xl': 1920px) !default;

/* scss edges */
$edges: ('t': top,
  'r': right,
  'b': bottom,
  'l': left) !default;

$edge-axis: x,
y !default;

/* scss borders */
$border-styles: solid,
dashed,
dotted,
double,
none;

/*scss layout */
$layout-gap: 12px;

/* if not pass any value mean value = 1 */
@mixin line-clamp($value: 1) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: $value;
}
