@use '../variables' as *;
@use '../mixin/background' as *;
@use '../mixin/flex' as *;

@mixin formCustoms {
  .mat-mdc-form-field-infix {
    z-index: 1;
    min-height: 50px;
    border-top: none;
    padding: 0;
    display: grid;
    place-items: center;

    mat-datepicker {
      position: absolute;
      bottom: 0;
    }
  }

  .mdc-text-field {
    .mdc-notched-outline {
      &__leading {
        background-color: $color-white;
      }

      &__trailing {
        background-color: $color-white;
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline__leading {
        border-radius: 10px 0 0 10px !important;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0 10px 10px 0 !important;
      }
    }

    &:not(.mdc-text-field--disabled, .mdc-text-field--focused, .mdc-text-field--invalid).mdc-text-field {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing,
      .mdc-notched-outline__notch {
        border-color: $mercury-color !important;
      }
    }
  }

  .mdc-text-field--disabled {
    .mdc-notched-outline {
      &__leading {
        background-color: $background-grey;
      }
      &__trailing {
        background-color: $background-grey;
      }
    }
  }
}

.app {
  &__login {
    background-image: url('../../assets/imgs/background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    background-position: center;

    .full-height {
      min-height: 100vh;
    }

    .p-v-20 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }

    .p-h-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .flex-center {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
    }

    .card-container {
      width: 100%;
      max-width: 466px;
    }

    .w-100 {
      width: 100%;
    }

    .m-h-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .container-fluid {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

    .card {
      position: relative;
      background-color: #fff;
      margin-bottom: 1.25rem;
      border: 1px solid #edf2f9;
      border-radius: 12px;

      .card-body {
        padding: 2rem;
        position: relative;

        .card-header {
          text-align: center;
          margin-bottom: 16px;
        }

        @include formCustoms();

        .flex-between {
          @include flexbox;
          @include align-items(center);
          @include justify-content(space-between);
        }

        h2,
        h3 {
          font-weight: 600;
        }

        h3 {
          color: $color-primary;
        }

        h2 {
          margin-bottom: 8px;
        }

        .header-hint {
          color: rgba($color: #000000, $alpha: 0.6);
        }

        .label {
          font-size: 18px;
          font-weight: 500;
        }

        .text-blur {
          color: $aluminium;
        }

        .button-request {
          padding: 0 !important;
        }

        .btn-login {
          width: 15em;

          &[disabled] {
            background-color: rgba($color: $color-primary, $alpha: 0.6);
            color: white;
          }
        }
      }
    }

    .shadow-lg {
      box-shadow: 0 1rem 3rem rgb(77 84 124 / 9%) !important;
    }

    .img-fluid {
      height: 50px;
    }

    .text-muted {
      color: #6c757d !important;
    }

    .font-size-13 {
      font-size: 13px !important;
    }
  }
}
