@use '../variables' as *;

$title: 35px;
$content-space: 64px;
$title-size: 32px;
$desc-size: 15px;
$desc-responsive: 14px;
$input-search-height: 70px;

@font-face {
  font-family: 'Moul';
  src: url('../../assets/fonts/Moul.ttf');
}

@mixin formCustom {
  .mat-mdc-form-field-infix {
    z-index: 1;
    min-height: 50px;
    border-top: none;
    padding: 0;
    display: grid;
    place-items: center;

    mat-datepicker {
      position: absolute;
      bottom: 0;
    }
  }

  .mdc-text-field {
    .mdc-notched-outline {
      &__leading {
        background-color: $color-white;
      }

      &__trailing {
        background-color: $color-white;
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline__leading {
        border-radius: 10px 0 0 10px !important;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0 10px 10px 0 !important;
      }
    }

    &:not(.mdc-text-field--disabled, .mdc-text-field--focused, .mdc-text-field--invalid).mdc-text-field {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing,
      .mdc-notched-outline__notch {
        border-color: $mercury-color !important;
      }
    }
  }

  .mdc-text-field--disabled {
    .mdc-notched-outline {
      &__leading {
        background-color: $background-grey;
      }
      &__trailing {
        background-color: $background-grey;
      }
    }
  }
}

.home {
  .text-color {
    color: $color-active;
  }

  .shift-list {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
  }

  .text-bor {
    color: #000;
  }

  .text-blur {
    color: rgba(255, 249, 249, 0.8) !important;
  }

  .text-white {
    color: #fff;
  }

  .text-routing {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .place-items-center {
    place-items: center;
  }

  .school-profile {
    width: 124px;
    height: 124px;
  }

  .mt-70 {
    margin-top: 70px;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .height-16 {
    height: 16px;
  }

  .height-100 {
    height: 100%;
    display: block;
  }

  .rounded-bottom-left-radius-0 {
    border-bottom-left-radius: 0 !important;
  }

  &.home-page {
    .bg-cover {
      .title-wrapper {
        .text-wrapper {
          max-width: 61em;
        }
      }
    }
  }

  .bg-cover {
    position: relative;
    width: 100%;

    &.bg-max-height {
      max-height: 48dvh;
      border-bottom-left-radius: 150px;
      overflow-y: hidden;

      &.rounded-none {
        border-bottom-left-radius: 0;
        max-height: 40dvh;

        &.max-height-30 {
          max-height: 30vh !important;
        }
      }
    }

    .background-wrapper {
      overflow-x: hidden;
      display: flex;
      justify-content: center;

      &.min-height-100 {
        min-height: 100dvh;
      }

      #background-cover {
        width: inherit;
        height: inherit;
      }
    }

    @media screen and (min-width: (map-get($map: $grid-breakpoints, $key: 'md') + 81px)) {
      min-width: 1024px;

      .background-wrapper {
        width: inherit;
      }
    }

    .title-wrapper {
      position: absolute;
      inset: 0;
      z-index: 1;

      span,
      strong {
        color: #fff;
      }

      .text-color {
        color: $color-active;
      }

      .button-request {
        padding: 25px !important;
        font-size: 18px;
        border-radius: 10px !important;
        background-color: #fff;

        span {
          color: #0093e9;
        }
      }

      .image-wrapper {
        margin-left: auto;

        img {
          width: 100%;
        }
      }

      .school-title {
        font-size: 30px;
      }

      .text-wrapper {
        color: #fff;

        .desc {
          font-size: $desc-size;
          line-height: normal;

          transition: font-size 0.3s ease;
        }

        .text-moul {
          font-family: 'Moul', 'Khmer, system ui';
          font-size: $title-size;
          line-height: normal;

          transition: font-size 0.3s ease;

          & > * {
            font-family: 'Moul', 'Khmer, system ui';
            font-size: $title-size;
            line-height: normal;

            transition: font-size 0.3s ease;
          }
        }
      }
    }
  }

  p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: normal;
  }

  .pos-relative {
    position: relative;
  }

  .main-title {
    font-size: 40px;
    line-height: normal;
    font-weight: bold;

    transition: font-size 0.3s ease;
  }

  .sub-title {
    color: $color-active;
    font-size: 16px;

    transition: font-size 0.3s ease;
    line-height: normal;
  }

  .desc-title {
    color: rgb(82, 82, 82);
    font-size: 16px;

    transition: font-size 0.3s ease;
    line-height: normal;
  }

  .content-wrapper {
    // mat form field custom style
    @include formCustom();

    .content {
      min-height: 32em;
      display: grid;
      place-items: center;
      padding: 12em 0;
      scroll-margin-top: 5em;

      &:nth-child(even) {
        background-color: $background-light-grey;
      }

      &.article {
        &__step-requesting {
          .card-wrapper {
            margin-top: 3em;

            .step-title {
              font-size: 20px;
              line-height: normal;
              font-weight: bold;

              transition: font-size 0.3s ease;
            }

            .step-desc {
              color: $aluminium;
              font-size: 16px;
              line-height: normal;

              transition: font-size 0.3s ease;
            }
          }
        }

        &__institution {
          .button-mat-search {
            height: 3.8em !important;
          }

          .card-list.card-swiper {
            swiper {
              border-radius: 0;
              min-height: 20em;
              display: grid;
              place-items: center;

              .aspect-card {
                aspect-ratio: 4/1;

                .image-no-scale {
                  background-size: contain;
                }
              }

              .swiper-pagination {
                bottom: 12%;

                .swiper-pagination-bullet {
                  cursor: pointer;
                  width: 35px;
                  height: 6px;
                  border-radius: 10px;
                }

                .swiper-pagination-bullet-active {
                  background-color: $color-primary;
                }
              }

              .swiper-wrapper {
                height: fit-content;
                margin-bottom: 4em;
              }
            }
          }
        }

        &__benefit-program {
          .benefit-image {
            margin-left: auto;

            img {
              width: 100%;
            }
          }
        }

        &__ministry {
          .main-title,
          .desc-title {
            text-align: center;
          }

          .logo-wrapper {
            margin-top: 4em;
            display: flex;
            flex-wrap: wrap;
            gap: 2em;
            justify-content: center;
          }
        }

        &__scholarship-requesting {
          padding: 2em 0;
          overflow: hidden;

          .main-title,
          .desc-title,
          .sub-title {
            text-align: center;
          }

          p {
            font-size: 14px;
          }

          .height-16 {
            height: 16px;
          }

          .height-24 {
            height: 24px;
          }

          .text-blur {
            color: $aluminium !important;
          }

          .bg-status {
            &__pending {
              background-color: rgba($color-warn, 0.1);
              color: $color-warn;
            }
            &__rejected {
              background-color: rgba($color-light-red, 0.1);
              color: $color-light-red;
            }
            &__approved {
              background-color: rgba($salem, 0.1);
              color: $salem;
            }
          }

          .event-none {
            pointer-events: none;
            cursor: none;
          }

          .detail-form {
            background-color: transparent;
            padding: 0;

            .button-browse {
              height: 35px !important;
              position: absolute;
              right: -8px;
              border-radius: 10px !important;
            }

            .button-remove {
              position: absolute;
              right: 0;
              top: 20px;

              &.mdc-fab--mini {
                width: 22px;
                height: 22px;

                mat-icon {
                  width: 18px;
                  height: 18px;
                  font-size: 18px;
                }
              }
            }

            .verify-check {
              mat-checkbox {
                &.ng-invalid {
                  &.ng-touched {
                    .mdc-checkbox {
                      &__background {
                        border-color: $color-light-red;
                      }
                    }

                    label {
                      color: $color-light-red;
                    }
                  }
                }
              }
            }

            .header-label {
              color: #000;
              font-size: 16px;
            }

            .button-verify {
              border-radius: 10px !important;
              height: 35px !important;
              padding-inline: 1.5em !important;
              margin-right: 0.6em;

              mat-icon {
                min-width: 1.125rem;
              }

              &.button-re-verify {
                background-color: $aluminium;
                pointer-events: none;
                cursor: none;
              }
            }

            ngx-otp-input {
              input {
                border: none;
                height: 50px;
                width: 45px;
                border-radius: 10px;
                background-color: $background-grey;
              }
            }

            .sub-form {
              padding: 1.5em;
              background: $background-grey;
              border-radius: 12px;

              .mat-mdc-form-field {
                .mat-mdc-form-field-icon-suffix {
                  padding-inline: 8px;

                  .button-search {
                    border-radius: 10px !important;
                    height: 35px !important;
                    width: 7em;

                    mat-icon {
                      min-width: 1.125rem;
                    }
                  }
                }
              }

              .no-subscript-wrapper {
                .mat-mdc-form-field-subscript-wrapper {
                  display: none;
                }

                mat-error {
                  &.custom-error {
                    font-size: 12px;
                    padding-left: 16px;
                  }
                }
              }
            }

            .scholarship-info {
              background: $athens-gray-color;
              border-radius: 12px;
            }

            .red-star {
              color: $color-light-red;
            }

            .member-card {
              border: 1px solid $black-squeeze-color;
              border-radius: 10px;
              padding: 1.2em 1em;
              cursor: pointer;
              box-shadow: none;
              transition: all 0.3 ease;

              &:hover {
                border: 1px solid transparent;
                box-shadow: 0 0 10px $iron-color;
              }

              &.active-card {
                border-color: $shamrock-color;
              }

              .text-blur {
                color: $aluminium !important;
              }

              .mat-mdc-checkbox {
                &.mat-primary {
                  .mdc-form-field {
                    .mdc-checkbox {
                      transform: scale(1.3);

                      &__background {
                        border-radius: 100%;
                        border-width: thin;

                        svg {
                          padding: 2px;
                        }
                      }

                      &__ripple {
                        border-radius: 100%;
                        border-width: thin;
                      }
                    }
                  }
                }
              }

              .info {
                mat-icon {
                  width: 20px;
                  height: 20px;
                  min-width: 20px;
                  min-height: 20px;
                }
              }
            }

            .image-wrapper {
              .image-box-wrapper {
                position: relative;
              }

              .image-box {
                width: 250px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-primary;
                cursor: pointer;

                img {
                  width: inherit;
                  height: inherit;
                  object-fit: cover;
                  box-shadow: 0 0 5px $mercury-color;
                }

                .button-remove {
                  position: absolute;
                  right: -8px;
                  top: -4px;
                }

                &__border-dashed {
                  border: 1px dashed $color-primary;

                  .grid {
                    place-items: center;
                  }
                }

                &.disabled-upload {
                  pointer-events: none;
                  background-color: $background-grey;
                  filter: grayscale(100%);
                }
              }
            }
          }

          mat-horizontal-stepper {
            &.disabled-stepper {
              mat-step-header {
                pointer-events: none;
                cursor: not-allowed;
              }
            }

            .mat-horizontal-stepper-wrapper {
              align-items: center;

              .mat-horizontal-content-container {
                width: 100%;
              }

              .mat-horizontal-stepper-header-container {
                margin-bottom: 1em;
                width: 60%;

                mat-step-header {
                  display: flex;
                  justify-content: center;
                  position: relative;
                  overflow: inherit;

                  .mat-step-icon {
                    transform: scale(1.5);
                    margin-right: 0;

                    mat-icon {
                      transform: scale(0.8);
                    }
                  }

                  .mat-step-label {
                    position: absolute;
                    bottom: -8px;

                    .mat-step-text-label {
                      font-weight: bold;
                    }
                  }
                  .mat-step-label-active {
                    .mat-step-text-label {
                      color: $color-primary;
                    }
                  }
                }
              }
            }

            .mat-stepper-horizontal-line {
              transition: border-top-color 0.5s ease-in;

              &.stepper-line-colored {
                border-top-color: $color-primary;
                border-top-width: 2px;
              }
            }

            .mat-horizontal-content-container {
              padding: 0;
            }
          }

          .button-step {
            button {
              font-size: 16px;
              padding-block: 22px !important;
            }
          }

          .next-button {
            min-width: 7em;
          }
        }

        &__footer {
          color: #fff;
          background-image: $linear-gradient-primary;
          min-height: 0;
          padding: 2em 0 4em 0;

          footer {
            .footer {
              &__title {
                max-width: 21em;

                p {
                  font-size: 12px;
                  font-family: 'Moul', 'Khmer, system ui';
                }
              }

              &__media {
                button {
                  box-shadow: none;
                  border: 1px solid #fff;
                }
              }

              &__contact {
                mat-icon {
                  min-width: 24px;
                }

                .head-title {
                  font-size: 16px;
                  font-weight: 600;
                }

                p {
                  font-size: 14px;
                }
              }

              &__title-bottom {
                text-align: center;
              }
            }

            mat-divider {
              border-top-color: #fff;
              opacity: 0.1;
            }
          }
        }

        &__search-school {
          place-items: start;
          padding: 3em 0;
          min-height: 36em !important;

          h2 {
            position: relative;
            padding-left: 14px;
            margin-bottom: 0;

            &::after {
              content: '';
              width: 5px;
              height: 100%;
              border-radius: 50px;
              position: absolute;
              background-color: $color-active;
              left: 0;
            }
          }

          .hide-filter {
            display: none;
          }

          .mat-mdc-form-field-subscript-wrapper {
            display: none;
          }

          mat-icon {
            height: 24px;
            width: 24px;
            min-width: 24px;
          }

          .input-search {
            .mat-mdc-form-field {
              position: relative;

              &.mat-ripple {
                background-color: #fff;
              }

              &.mat-form-field-appearance-outline {
                .mat-mdc-form-field-infix {
                  min-height: $input-search-height;

                  input {
                    font-size: 16px;
                    min-height: inherit;
                    line-height: normal;
                  }
                }

                .mat-mdc-text-field-wrapper {
                  padding-inline: 16px;

                  .mat-mdc-form-field-flex {
                    .mdc-notched-outline__leading {
                      border-radius: 50px 0 0 50px !important;
                      width: 50px;
                    }

                    .mdc-notched-outline__trailing {
                      border-radius: 0 50px 50px 0 !important;
                    }
                  }
                }

                //!filter action at right side of input
                .filter-action {
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  .vertical-line {
                    height: 70%;
                    width: 1px;
                    background: $athens-gray-color;
                  }

                  .mat-mdc-form-field-infix {
                    min-height: $input-search-height - 15;
                    width: 7em;
                  }

                  .mat-mdc-text-field-wrapper {
                    margin: 0 !important;

                    .mat-mdc-form-field-flex {
                      .mdc-notched-outline__leading {
                        border: none !important;
                      }

                      .mdc-notched-outline__trailing {
                        border: none !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .school-wrapper {
            .logo {
              img {
                height: 55px;
                width: 55px;
                object-fit: cover;
                border-radius: 100%;
              }
            }

            p {
              font-size: 14px;

              mat-icon {
                height: 20px;
                width: 20px;
              }
            }

            .school-card {
              cursor: pointer;
              background-color: $black-squeeze-light;
              border-radius: 10px;

              &.cursor-auto {
                cursor: auto;
              }

              button[disabled='true'] {
                opacity: 0.5;
                cursor: not-allowed;

                mat-icon {
                  filter: grayscale(100%);
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1515px) {
    .bg-cover {
      .title-wrapper {
        .text-wrapper {
          .text-moul {
            font-size: $title-size;

            & > * {
              font-size: $title-size;
            }
          }
        }
      }
    }
  }

  .date {
    width: 137px;
  }

  @media screen and ((min-width: 1111px) and (max-width: 1390px)) {
    .date {
      width: 100px;
    }
  }

  @media screen and (max-width: 1165px) {
    .bg-cover {
      .title-wrapper {
        .text-wrapper {
          .text-moul {
            font-size: $title-size - 10px;

            & > * {
              font-size: $title-size - 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: (map-get($map: $grid-breakpoints, $key: 'md') + 80px)) {
    .mt-70 {
      margin-top: 0;
    }

    .bg-cover {
      .title-wrapper {
        flex-direction: column;
        justify-content: center;
        margin-top: 70px;

        &.align-item-start {
          align-items: flex-start;
        }

        .image-wrapper {
          width: 100%;
          text-align: right;

          img {
            width: 13em;
          }
        }

        .text-wrapper {
          .desc {
            font-size: $desc-responsive;
          }
        }
      }
    }
  }

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
    .bg-cover {
      &.bg-max-height {
        &.rounded-none {
          max-height: 56dvh;
        }
      }
    }

    .md-hidden {
      display: none;
    }

    .main-title {
      font-size: 28px;
    }

    .sub-title,
    .desc-title,
    p {
      font-size: $desc-responsive;
    }

    .content-wrapper {
      .content {
        padding: 8em 0;

        &.article {
          &__benefit-program {
            .benefit-image {
              margin-left: 0;
            }
          }

          &__step-requesting {
            .card-wrapper {
              .step-title {
                font-size: 16px;
              }
            }
          }

          &__search-school {
            .input-search {
              .mat-mdc-form-field {
                &.mat-form-field-appearance-outline {
                  .mat-mdc-form-field-infix {
                    min-height: $input-search-height - 10;

                    input {
                      font-size: 14px;
                    }
                  }

                  .filter-action {
                    &.show-filter {
                      display: none;
                    }
                  }
                }
              }
            }

            .hide-filter {
              display: block !important;
            }
          }

          &__scholarship-requesting {
            mat-horizontal-stepper {
              .mat-horizontal-stepper-wrapper {
                .mat-horizontal-stepper-header-container {
                  width: 100%;
                }
              }
            }
          }
        }

        .grid.align-items-center {
          place-items: center;
        }
      }
    }
  }

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
    .bg-cover {
      &.bg-max-height {
        max-height: 65dvh;

        &.rounded-none {
          max-height: 60dvh;
        }
      }

      .title-wrapper {
        .text-wrapper {
          .text-moul {
            font-size: $title-size - 16px;

            & > * {
              font-size: $title-size - 16px;
            }
          }
        }

        .school-title {
          font-size: 18px;
        }
      }
    }

    &.home-page {
      .bg-cover {
        .title-wrapper {
          .text-wrapper {
            .text-moul {
              font-size: $title-size - 12px;

              & > * {
                font-size: $title-size - 12px;
              }
            }
          }
        }
      }
    }

    .school-profile {
      width: 6em;
      height: 6em;
    }

    .main-title {
      font-size: 20px;
    }

    .content-wrapper {
      .content {
        padding: 4em 0;

        &.article {
          &__step-requesting {
            .card-wrapper {
              .step-title {
                font-size: 15px;
              }

              .step-desc {
                font-size: $desc-responsive;
              }
            }
          }

          &__institution {
            .card-list.card-swiper {
              swiper {
                min-height: 12em;
                display: block;
              }
            }
          }

          &__scholarship-requesting {
            mat-horizontal-stepper {
              mat-step-header {
                .mat-step-icon {
                  transform: scale(1.2);
                }
              }
            }

            .button-step {
              button {
                font-size: 14px;
              }
            }

            .detail-form {
              .image-wrapper {
                justify-content: center;
              }
            }
          }

          // &__search-school {
          //   .school-wrapper {
          //     .school-card {
          //       display: grid;
          //       row-gap: 16px;
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.poor-id-member-dialog {
  .no-subscript-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .header-label {
    line-height: normal;
  }

  .search-form {
    .mat-mdc-form-field.mat-form-field-appearance-outline {
      @include formCustom();
    }

    .mat-mdc-form-field {
      .mat-mdc-form-field-icon-suffix {
        padding-inline: 8px;

        .button-search {
          border-radius: 10px !important;
          height: 35px !important;
          width: 7em;

          mat-icon {
            min-width: 1.125rem;
          }
        }
      }
    }
  }

  .mat-mdc-mini-fab {
    transform: translateY(-10px);
    box-shadow: none;
  }

  .no-data-wrapper {
    height: 35vh;
    display: grid;
    place-items: center;
  }

  mat-dialog-content {
    padding: 0 !important;
    color: $color-text !important;

    .group-btn {
      button {
        height: 3.5em !important;
        width: 10em;
      }
    }

    .member-card {
      border: 1px solid $black-squeeze-color;
      border-radius: 10px;
      padding: 1.2em 1em;
      cursor: pointer;
      box-shadow: none;
      transition: box-shadow 0.3 ease;

      &:hover {
        border: 1px solid transparent;
        box-shadow: 0 0 10px $iron-color;
      }

      &.active-card {
        border-color: $shamrock-color;
      }

      .mat-mdc-checkbox {
        &.mat-primary {
          .mdc-form-field {
            .mdc-checkbox {
              transform: scale(1.3);

              &__background {
                border-radius: 100%;
                border-width: thin;

                svg {
                  padding: 2px;
                }
              }

              &__ripple {
                border-radius: 100%;
                border-width: thin;
              }
            }
          }
        }
      }
    }
  }
}
